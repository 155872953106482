// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
	root: ROOTS_AUTH,
	login: path(ROOTS_AUTH, '/login'),
	loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
	register: path(ROOTS_AUTH, '/register'),
	registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
	resetPassword: path(ROOTS_AUTH, '/reset-password'),
	verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
	comingSoon: '/coming-soon',
	maintenance: '/maintenance',
	pricing: '/pricing',
	payment: '/payment',
	about: '/about-us',
	contact: '/contact-us',
	faqs: '/faqs',
	page404: '/404',
	page500: '/500',
	components: '/components'
};

export const PATH_DASHBOARD = {
	root: ROOTS_DASHBOARD,
	auth: {
		verify_2fa: path(ROOTS_DASHBOARD, '/auth/verify'),
	},
	general: {
		app: path(ROOTS_DASHBOARD, '/dashboard'),
		customers: path(ROOTS_DASHBOARD, '/customers'),
		ind_customers: path(ROOTS_DASHBOARD, '/customers/individuals'),
		entity_customers: path(ROOTS_DASHBOARD, '/customers/entities'),
		onboarding_requests: path(ROOTS_DASHBOARD, '/customers/onboarding_requests'),
		results: path(ROOTS_DASHBOARD, '/aml/screening_results'),
		aml_search: path(ROOTS_DASHBOARD, '/aml/search'),
		cases: path(ROOTS_DASHBOARD, '/aml/cases'),
	},
	accounts: {
		root: path(ROOTS_DASHBOARD, '/accounts'),
		list: path(ROOTS_DASHBOARD, '/accounts/list'),
		statements: path(ROOTS_DASHBOARD, '/accounts/statements'),
	},
	securities: {
		root: path(ROOTS_DASHBOARD, '/securities'),
		list: path(ROOTS_DASHBOARD, '/securities/list'),
		transactions: path(ROOTS_DASHBOARD, '/securities/transactions'),
		distributions: path(ROOTS_DASHBOARD, '/securities/distributions'),
	},
	reports: {
		clientReport: path(ROOTS_DASHBOARD, '/reports/clients'),
		audits: path(ROOTS_DASHBOARD, '/reports/audits'),
	},
	settings: {
		root: path(ROOTS_DASHBOARD, '/settings'),
		user_management: path(ROOTS_DASHBOARD, '/settings/users'),
		recurring_screenings: path(ROOTS_DASHBOARD, '/settings/recurring_screenings'),
		fee_schedules: {
			root: path(ROOTS_DASHBOARD, '/settings/fee_schedules'),
			list: path(ROOTS_DASHBOARD, '/settings/fee_schedules/list'),
		},
		forms: path(ROOTS_DASHBOARD, '/settings/forms'),
		// developer: path(ROOTS_DASHBOARD, '/developer'),
		// appearance: path(ROOTS_DASHBOARD, '/appearance'),
	}
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
