import axios from 'axios';

let apiToken: string | undefined;

const apiClient = axios.create({
	withCredentials: true,
	baseURL: process.env.REACT_APP_AML_API_ENDPOINT
});

export const setToken = (token: string | undefined) => {
	apiToken = token;
	apiClient.interceptors.request.use(function (config) {
		if (apiToken) {
			config.headers.Authorization = `Bearer ${apiToken}`;
			config.headers.Accept = "application/json";
		}
		return config;
	});

	// Add a response interceptor to check for 2FA
	apiClient.interceptors.response.use(
		function (response) {
			
			return response;
		},
		function (error) {
			// If the response has 2fa_required: true, perform an action
			if(	
				error.response
				&& error.response.data
				&& error.response.data.require_2fa
				&& error.response.data.require_2fa === true
			) {
				handle2FARequired();
				return Promise.reject(error);
			}
		}
	);
}

// Janky ass shit
// This allows for the custom function settings to handle 2fa failtures
let handle2FARequired: () => void = () => {};
export const set2FAHandler = (handler: () => void) => {
	handle2FARequired = handler;
};

export const removeToken = () => {
	apiToken = undefined;
}
export default apiClient;