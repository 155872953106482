import { Suspense, lazy, useContext } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import SetNewPassword from 'pages/authentication/SetNewPassword';
import { AppContextProvider } from 'contexts/appContext';
import HasPermission from 'services/permissionsService';
import GuestAuthGuard from 'guards/GuestAuthGuard';
import BaseGuestLayout from 'layouts/guest/BaseGuestLayout';


// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const { pathname } = useLocation();
	const isDashboard = pathname.includes('/dashboard');

	return (
		<Suspense
			fallback={
				<LoadingScreen
					sx={{
						...(!isDashboard && {
							top: 0,
							left: 0,
							width: 1,
							zIndex: 9999,
							position: 'fixed'
						})
					}}
				/>
			}
		>
			<Component {...props} />
		</Suspense>
	);
};

export default function Router() {
	const { user } = useContext(AppContextProvider);
	const baseAppRoutes = [
		{ path: '*', element: <div>Page not Found</div> },
		{ path: '/app', element: <Navigate to="/app/dashboard" replace /> },
		{ path: 'dashboard', element: <GeneralApp /> },
		// { path: 'developer', element: <div>Developers</div> },
		// { path: 'appearance', element: <div>Appearance</div> },
	];

	const individualRoutes = [
		{ path: 'customers/individuals/new', element: <IndividualView editMode = {true}/> },
		{ path: 'customers/individuals/:id', element: <IndividualOverview /> },
		{ path: 'customers/individuals/trashed/:id', element: <IndividualOverview /> },
		{ path: 'customers/individuals', element: <Individuals /> },
	];
	if(HasPermission({permissions: ["can:clientsManagementIndividualsView"]})){
		baseAppRoutes.push(...individualRoutes);
		if(HasPermission({permissions: ["can:clientsManagementIndividualsAddressesView"]})){
			baseAppRoutes.push({path: 'customers/individuals/:id/addresses', element: <Addresses /> },);
		}
	}

	const entityRoutes = [
		{ path: 'customers/entities/new', element: <EntityView editMode={true} /> },
		{ path: 'customers/entities/:id', element: <EntityOverview /> },
		{ path: 'customers/entities/trashed/:id', element: <EntityOverview /> },
		{ path: 'customers/entities', element: <Entities /> },
	];
	if(HasPermission({permissions: ["can:clientsManagementEntitiesView"]})){
		baseAppRoutes.push(...entityRoutes);
		if(HasPermission({permissions: ["can:clientsManagementEntitiesAddressesView"]})){
			baseAppRoutes.push({path: 'customers/entities/:id/addresses', element: <Addresses /> },);
		}
	}

	const onboardingRoutes = [
		{ path: 'customers/onboarding_requests', element: <OnboardingRequestList /> },
		{ path: 'customers/onboarding_requests/new', element: <OnboardingRequestView editMode={true} /> },
		{ path: 'customers/onboarding_requests/:id', element: <OnboardingRequestOverview /> },
		{ path: 'customers/onboarding_requests/trashed/:id', element: <OnboardingRequestView /> },
	];
	// TODO
	// if(HasPermission({permissions: ["can:clientsManagementOnboardingRequestsView"]})){
		baseAppRoutes.push(...onboardingRoutes);
	// }

	if(HasPermission({permissions: ["can:userReportsClientsReportRun"]})){
		baseAppRoutes.push({ path: 'reports/clients', element: <ClientReportView /> });
	}
	if(HasPermission({permissions: ["can:userReportsAuditsReportRun"]})){
		baseAppRoutes.push({ path: 'reports/audits', element: <AuditsList /> });
	}

	const userRoutes = [
		{ path: 'settings/users/trashed/:id', element: <UserView /> },
		{ path: 'settings/users/:user_id', element: <UserView /> },
		{ path: 'settings/users', element: <Users /> },
		{ path: 'settings/forms/:id', element: <FormView /> },
		{ path: 'settings/forms', element: <FormsList /> },
	];
	if(HasPermission({permissions: ["can:userManagementBasicRead"]})){
		baseAppRoutes.push(...userRoutes);
	}

	if (user?.has_aml&& HasPermission({permissions: ["can:amlManagementScreeningsView"]})) {
		baseAppRoutes.push({ path: 'aml/screening_results', element: <ScreeningResultsView /> },);
	}
	if (user?.has_aml&& HasPermission({permissions: ["can:amlManagementSearchView"]})) {
		baseAppRoutes.push({ path: 'aml/search', element: <AmlSearch /> },);
	}
	if (user?.has_aml&& HasPermission({permissions: ["can:amlManagementCasesView"]})) {
		baseAppRoutes.push({ path: 'aml/cases/:id', element: <CaseView /> },);
		baseAppRoutes.push({ path: 'aml/cases', element: <CasesList /> },);
	}
	if (user?.has_aml&& HasPermission({permissions: ["can:amlManagementRecurringScreeningsView"]})) {
		baseAppRoutes.push({ path: 'settings/recurring_screenings', element: <RecurringScreenings /> },);
	}

	const accountRoutes = [
		{ path: 'accounts/new', element: <AccountView editMode = {true}/> },
		{ path: 'accounts/:id', element: <AccountOverview /> },
		{ path: 'accounts/trashed/:id', element: <AccountOverview /> },
		{ path: 'accounts/list', element: <AccountsList /> },
		{ path: 'accounts/statements/:id/:statement_group_id', element: <StatementsList /> },
		{ path: 'settings/fee_schedules/:id', element: <FeeScheduleView /> },
		{ path: 'settings/fee_schedules/trashed/:id', element: <FeeScheduleView /> },
		{ path: 'settings/fee_schedules/list', element: <FeeSchedulesList /> },
	];
	if (user?.has_accounts) {
		baseAppRoutes.push(...accountRoutes);
	}

	const securitiesRoutes = [
		{ path: 'securities/new', element: <SecurityView /> },
		{ path: 'securities/:id', element: <SecurityOverview /> },
		{ path: 'securities/list', element: <SecuritiesList /> },
		{ path: 'securities/transactions', element: <TransactionsView /> },
		{ path: 'securities/transactions/activate/:id', element: <ActivateSecurityView /> },
		{ path: 'securities/transactions/internal_transfer/:id', element: <InternalTransferSecurityView /> },
		{ path: 'securities/transactions/external_transfer/:id', element: <ExternalTransferSecurityView /> },
		{ path: 'securities/transactions/search', element: <TransactionsSearchView /> },
		{ path: 'securities/distributions/:id/:sec_id', element: <Distributions /> },

	];
	if (user?.has_securities) {
		baseAppRoutes.push(...securitiesRoutes);
	}

	return useRoutes([
		{ path: '*', element: <Navigate to="/app" replace /> },
		{
			path: 'auth',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<Login />
						</GuestGuard>
					)
				},
				{ path: 'login-unprotected', element: <Login /> },
				{ path: 'reset-password', element: <ResetPassword /> },
				{ path: 'set-new-password/:token', element: <SetNewPassword /> },
				{ path: 'verify', element: <VerifyCode /> }
			]
		},
		// Dashboard Routes
		{
			path: 'app',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [...baseAppRoutes]
		},
		{
			path: 'guest',
			element: (
				<GuestAuthGuard>
					<BaseGuestLayout />
				</GuestAuthGuard>
			),
			children: [
				{
					path: 'forms/:hash',
					element: <FormRequestPage />
				},
			]
		},
	]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Individuals = Loadable(lazy(() => import('../pages/dashboard/customers/individuals')));
const Addresses = Loadable(lazy(() => import('../pages/dashboard/customers/addresses')));
const AmlSearch = Loadable(lazy(() => import('../pages/dashboard/aml/SearchView')));
const CasesList = Loadable(lazy(() => import('../pages/dashboard/cases/CasesList')));
const CaseView = Loadable(lazy(() => import('../pages/dashboard/cases/CaseView')));

const AccountsList = Loadable(lazy(() => import('../pages/dashboard/accounts/AccountsList')));
const AccountView = Loadable(lazy(() => import('../pages/dashboard/accounts/AccountView')));
const StatementsList = Loadable(lazy(() => import('../pages/dashboard/accounts/statements/StatementsList')));
const AccountOverview = Loadable(lazy(() => import('../pages/dashboard/accounts/AccountOverview')));
const FeeSchedulesList = Loadable(lazy(() => import('../pages/dashboard/settings/fee_schedules/FeeSchedulesList')));
const FeeScheduleView = Loadable(lazy(() => import('../pages/dashboard/settings/fee_schedules/FeeScheduleView')));

const SecuritiesList = Loadable(lazy(() => import('../pages/dashboard/securities/SecuritiesList')));
const SecurityView = Loadable(lazy(() => import('pages/dashboard/securities/SecurityView')));
const SecurityOverview = Loadable(lazy(() => import('pages/dashboard/securities/SecurityOverview')));
const TransactionsView = Loadable(lazy(() => import('pages/dashboard/securities/TransactionsView')));
const ActivateSecurityView = Loadable(lazy(() => import('pages/dashboard/securities/ActivateSecurityView')));
const InternalTransferSecurityView = Loadable(lazy(() => import('pages/dashboard/securities/InternalTransferSecurityView')));
const ExternalTransferSecurityView = Loadable(lazy(() => import('pages/dashboard/securities/ExternalTransferSecurityView')));
const TransactionsSearchView = Loadable(lazy(() => import('pages/dashboard/securities/TransactionsSearchView')));
const Distributions = Loadable(lazy(() => import('pages/dashboard/securities/Distributions')));

const RecurringScreenings = Loadable(lazy(() => import('../pages/dashboard/settings/recurring_screenings/RecurringScreenings')));
const Users = Loadable(lazy(() => import('../pages/dashboard/users')));
const UserView = Loadable(lazy(() => import('../pages/dashboard/users/UserView')));

const IndividualView = Loadable(lazy(() => import('pages/dashboard/customers/individuals/IndividualView')));
const IndividualOverview = Loadable(lazy(() => import('pages/dashboard/customers/individuals/IndividualOverview')));
const Entities = Loadable(lazy(() => import('../pages/dashboard/customers/entities')));
const EntityView = Loadable(lazy(() => import('pages/dashboard/customers/entities/EntityView')));
const EntityOverview = Loadable(lazy(() => import('pages/dashboard/customers/entities/EntityOverview')));

const OnboardingRequestList = Loadable(lazy(() => import('pages/dashboard/customers/onboarding/OnboardingRequestsList')));
const OnboardingRequestOverview = Loadable(lazy(() => import('pages/dashboard/customers/onboarding/OnboardingRequestOverview')));
const OnboardingRequestView = Loadable(lazy(() => import('pages/dashboard/customers/onboarding/OnboardingRequestView')));

const FormView = Loadable(lazy(() => import('pages/dashboard/settings/forms/FormView')));
const FormsList = Loadable(lazy(() => import('pages/dashboard/settings/forms/FormsList')));

// Reports
const ClientReportView = Loadable(lazy(() => import('pages/dashboard/reports/ClientsReport')));
const ScreeningResultsView = Loadable(lazy(() => import('pages/dashboard/aml/ScreeningResults')));

// Audits
const AuditsList = Loadable(lazy(() => import('../pages/dashboard/audits/AuditsList')));

const FormRequestPage = Loadable(lazy(() => import('../pages/guest/onboarding/FormRequestPage')));

// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
