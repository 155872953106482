import React, { FunctionComponent, useEffect, useState } from 'react';
import apiClient, { removeToken, set2FAHandler, setToken } from 'services/apiClient';

export interface UserInterface {
	id: string,
	email: string
	name: string
	roles?: Array<any>
	client_name?: string
	has_aml: boolean
	has_accounts: boolean
	has_client_management: boolean
	has_securities: boolean
	has_adverse_media: boolean
	has_ai_summary: boolean
	is_beta_client: boolean
	permissions?: string[]
}

// interface TwoFactorAuthenticationDetails {
// 	secret: string
// 	qr_image: string
// }
interface AppStateInterface {
	require_2fa?: Boolean
	user?: UserInterface | null
	token?: string
	loggedIn: boolean,
	guestLoggedIn?: boolean
}

interface AppDispatcherProps {
	event: 'login' | 'logout' | 'guest-login' | 'disable2fa' | 'enable2fa',
	params: any
}

const AppContextWrapper: FunctionComponent = ({ children }) => {
	const [appState, setAppState] = useState<AppStateInterface>({
		loggedIn: sessionStorage.getItem('loggedIn') == 'true' || false,
		require_2fa: sessionStorage.getItem('require_2fa') == 'true' || false,
		guestLoggedIn: sessionStorage.getItem('guestLoggedIn') == 'true' || false,
		token: sessionStorage.getItem('token') ?? undefined
	});
	const { loggedIn, guestLoggedIn, token, require_2fa } = appState;
	set2FAHandler(() => {
		sessionStorage.setItem('require_2fa', 'true');
		setAppState((prevState) => ({
			...prevState,
			require_2fa: true,
		}));
	});

	const getUser = async () => {
		try {
			const { data, status } = await apiClient.get('/api/user')
			const { user, message } = data;
			if (status === 200 && user) {
				setAppState({
					loggedIn: true,
					user,
					token
				});
				// @ts-ignore-start
				pendo.initialize({
					visitor: {
						id: user.id,             // Required if user is logged in, default creates anonymous ID
						email: user.email,       // Recommended if using Pendo Feedback, or NPS Email
						full_name: user.name,     // Recommended if using Pendo Feedback
						aml: user.has_aml,
						client_management: user.has_client_management,
						securities: user.has_securities,
						// role:         // Optional
						// You can add any additional visitor level key-values here,
						// as long as it's not one of the above reserved names.
					},

					account: {
						id: user.client_id,            // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
						name: user.client_name,      // Optional
						identifier: user.client_identifier,
						has_aml: user.has_aml,
						has_client_management: user.has_client_management,
						has_securities: user.has_securities,
						// is_paying:               // Recommended if using Pendo Feedback
						// monthly_value:           // Recommended if using Pendo Feedback
						// planLevel:               // Optional
						// planPrice:               // Optional
						// creationDate:            // Optional

						// You can add any additional account level key-values here,
						// as long as it's not one of the above reserved names.
					}
				});
				// @ts-ignore-end
			}
		} catch (e) {
			setAppState({
				loggedIn: false
			});
		}
	}

	useEffect(() => {
		setToken(appState.token);
		if (appState.loggedIn && !appState.require_2fa && !appState.user) {
			getUser()
		}
	}, [loggedIn, guestLoggedIn, token, require_2fa]);

	return (
		<AppContextProvider.Provider value={appState}>
			<AppContextDispatcher.Provider value={({ event, params }: AppDispatcherProps) => {
				switch (event) {
					case 'logout':
						sessionStorage.setItem('loggedIn', 'false');
						sessionStorage.setItem('guestLoggedIn', 'false');
						sessionStorage.removeItem('token');
						removeToken();
						setAppState({ guestLoggedIn:false, loggedIn: false, user: null });
						break;
					case 'login':
						sessionStorage.setItem('loggedIn', 'true');
						sessionStorage.removeItem('guestLoggedIn');
						sessionStorage.setItem('require_2fa', params.require_2fa);
						sessionStorage.setItem('token', params.token);
						setToken(params.token);
						setAppState({
							loggedIn: true,
							user: undefined,
							token: params.token,
							require_2fa: params.require_2fa
						});
						break;
					case 'disable2fa':
						sessionStorage.setItem('require_2fa', 'false');
						setAppState(() => ({ ...appState, require_2fa: false }));
						break;
					case 'enable2fa':
						sessionStorage.setItem('require_2fa', 'true');
						setAppState(() => ({ ...appState, require_2fa: true }));
						break;
					case 'guest-login':
						sessionStorage.setItem('guestLoggedIn', 'true');
						sessionStorage.setItem('token', params.token);
						setToken(params.token);
						setAppState({ guestLoggedIn: true, loggedIn:false, user: undefined, token: params.token });
						break;
				}
			}} >
				{children}
			</AppContextDispatcher.Provider>
		</AppContextProvider.Provider>
	)
}

export const AppContextProvider = React.createContext<AppStateInterface>({
	loggedIn: false
})

export const AppContextDispatcher = React.createContext<Function>(() => { });

export default AppContextWrapper;